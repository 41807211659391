import {
  registerApplication,
  start,
  addErrorHandler,
  LOAD_ERROR,
  getAppStatus,
  unloadApplication,
} from 'single-spa';
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    // eslint-disable-next-line no-restricted-properties
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();

const loadingApplicationRetries: {
  [appName: string]: number;
} = {};

const maxRetries = 3;

addErrorHandler(err => {
  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
    if (!loadingApplicationRetries[err.appOrParcelName]) {
      loadingApplicationRetries[err.appOrParcelName] = 0;
    } else {
      loadingApplicationRetries[err.appOrParcelName] += 1;
    }

    if (loadingApplicationRetries[err.appOrParcelName] < maxRetries) {
      console.log(
        `Retrying to load ${err.appOrParcelName}...`,
        `Attempt ${loadingApplicationRetries[err.appOrParcelName] + 1}`,
      );
      System.delete(System.resolve(err.appOrParcelName));
      setTimeout(() => unloadApplication(err.appOrParcelName), 1000);
    }
  }
});

start();
